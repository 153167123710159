import './App.css';
import { useMemo, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import ContactForm from './ContactForm'

function App() {
  return (
    <div className="app-container">
      <ContactForm />
    </div>
  );
}

export default App;
