// vim: ft=javascriptreact
import { useMemo, useState, useEffect, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import {
  Alert,
  Button,
  Form,
  Row,
  Col,
  Modal,
  ProgressBar,
} from 'react-bootstrap'

import './ContactForm.scss'

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const ContactForm = () => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [message, setMessage] = useState('')
  const [sending, setSending] = useState(false)
  const [status, setStatus] = useState('')
  const [uploadPercent, setUploadPercent] = useState(0)
  const [showDone, setShowDone] = useState(false)

  const [files, setFiles] = useState([])
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    },
    maxSize: 1024 * 1024 * 10,
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  })
  const style = useMemo(() => ({
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const isValidEmail = email.length > 0 && /^.+@.+\..+$/.test(email)
  const canSend = !!name && isValidEmail && !!message && files.length > 0

  //const acceptedFileItems = acceptedFiles.map(file => (
  //  <li key={file.path}>
  //    {file.path} - {file.size} bytes
  //  </li>
  //));

  const thumbs = files.map(file => (
    <div className="thumb" key={file.name}>
      <div className="thumb-inner">
        <img
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach(file => URL.revokeObjectURL(file.preview));
  }, []);

  const submit = useCallback(async () => {
    setSending(true)
    setUploadPercent(0)
    const fileKeys = []
    const promises = acceptedFiles.map(async (file, i) => {
      console.log(i)
      setStatus(`Uploading image${(files.length == 1 ? '' : 's')}`)
      console.log('Setting progress to', Math.round((i + 1) / acceptedFiles.length) * 100)
      setUploadPercent(0)
      const promise = new Promise(async (uploadResolve, uploadReject) => {
        const req = await fetch(`${process.env.REACT_APP_API_PREFIX}/request_upload`, {
          method: 'POST',
          body: JSON.stringify({
            name: file.name,
            contentType: file.type,
          })
        })

        if (req.ok) {
          const response = await req.json()
          console.log('got', response)
          const { uploadUrl } = response

          const xhr = new XMLHttpRequest()
          xhr.upload.onprogress = (e) => {
            if (e.lengthComputable) {
              const percent = Math.round(e.loaded / e.total * 100)
              setUploadPercent(percent)
            }
          }

          xhr.onload = () => {
            if (xhr.status === 200) {
              fileKeys.push(response.key)
              console.log('upload complete')
              setUploadPercent(100)
              uploadResolve()
            }
          }

          xhr.onerror = (e) => {
            console.log('upload failed', e)
            uploadReject(e)
          }
          xhr.open('PUT', uploadUrl, true)
          xhr.setRequestHeader('Content-Type', file.type)
          xhr.send(file)
        }
      })

      return promise
    })

    await Promise.all(promises)

    setStatus('Sending message...')

    const req = await fetch(`${process.env.REACT_APP_API_PREFIX}/send_email`, {
      method: 'POST',
      body: JSON.stringify({
        name,
        email,
        message,
        fileKeys,
      })
    })

    setStatus('Done!')
    setSending(false)
    setUploadPercent(0)
    setMessage('')
    setFiles([])
    setShowDone(true)
    setTimeout(() => {
      setShowDone(false)
    }, 10000)

    console.log(req)
  }, [acceptedFiles])

  return (
    <>
      {showDone && (
        <Alert variant="success">
          Message sent.
        </Alert>
      )}

      <Modal backdrop="static" centered keyboard={false} show={sending}>
        <Modal.Header>
          <Modal.Title>Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ProgressBar animated variant="warning" now={uploadPercent} />
          {status}
        </Modal.Body>
      </Modal>
      <Row>
        <Col sm="6" xs="12">
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" value={email} isValid={isValidEmail} onChange={(e) => setEmail(e.target.value.replace(/ /g, ''))} />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col sm="6" xs="12">
          <Form.Group controlId="name" className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              value={name}
              isValid={!!name}
              onChange={(e) => setName(e.target.value)} />
          </Form.Group>

        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <Form.Group className="mb-3">
            <Form.Label>Message</Form.Label>
            <Form.Control as="textarea" placeholder="" rows={5} value={message} onChange={(e) => setMessage(e.target.value)} />
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col sm="12">
          <Form.Group className="mb-3">
            <Form.Label>Image(s)</Form.Label>
            <div className="file-uploader">
              <label className="file-label" {...getRootProps()} style={style}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
              </label>
              <aside className="thumbs-container">
                {thumbs}
              </aside>
            </div>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col sm="12 pb-5">
          <Button disabled={files.length === 0 || sending} onClick={submit} variant="primary">{sending ? 'Sending...' : 'Send'}</Button>
        </Col>
      </Row>
    </>
  );
}

export default ContactForm
